export const branchList = [];
const branches = ['Dhanmondi', 'Baridhara', 'Gulshan 1', 'Uttara',]

for (let i = 0; i < 100; i++) {
    branchList.push({
        id: i,
        branchId: 'PRID122' + i,
        branchName: branches[Math.floor(Math.random() * 4)],
        numberOfStudent: '256'+i,
        numberOfAdmin: '45',
        numberOfTrainer: '85',
        numberOfCourse: '5',
        courses: 'C125, C122, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125, C125',
        createdAt: '25 May, 2019, 9:15pm',
    });
}


export const branchContracts = [];

for (let i = 0; i < 100; i++) {
    branchContracts.push({
        id: i,
        branchId: 'PRID122' + i,
        branchName: branches[Math.floor(Math.random() * 4)],
        address: 'Dhanmondi 32, Dhaka 1230, Bangladesh',
        contractFrom: '25 May, 2019',
        contractTo: '25 May, 2022',
        contractBy: 'Hafiz Rahman',
        createdAt: '25 May, 2019, 9:15pm'
    });
}

export const supports = [];

for (let i = 0; i < 100; i++) {
    supports.push({
        id: i,
        branchId: 'PRID122' + i,
        branchName: branches[Math.floor(Math.random() * 4)],
        supportAndService: 'PTE Practice, Practice Test, Mock Test, Quiz Test, One-On-One Appointment',
        addedBy: 'Hafiz Rahman',
        createdAt: '25 May, 2019, 9:15pm'
    });
}

export const branchAdOns = [];

for (let i = 0; i < 100; i++) {
    branchAdOns.push({
        id: i,
        branchId: 'PRID122' + i,
        branchName: branches[Math.floor(Math.random() * 4)],
        numberOfPracticeTest: '5',
        numberOfMockTest: '5',
        numberOfQuiz: '5',
        numberOfOneOnOneApt: '3',
        createdAt: '25 May, 2019, 9:15pm'
    });
}
